import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LayoutView from '@/views/LayoutView.vue'
import HomeView from '@/views/home/HomeView.vue';
import M_HomeView from '@/views/home/M_HomeView.vue'
const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'layout',
        redirect: '/home',
        component: LayoutView,
        children: [
            {
                path: 'home',
                name: 'home',
                component: HomeView
            },
            {
                path: 'main',
                name: 'main',
                component: () => import('@/views/main/MainView.vue'),
                meta: { title: 'PC端主界面', hidden: false, threeMenu: true },
                children: [
                    {
                        path: 'recommendDetails',
                        name: 'recommendDetails',
                        meta: { title: 'PC端软件详情页', hidden: false },
                        component:  () => import('@/views/details/RecommendDetailsView.vue')
                    },
                    {
                        path: 'list',
                        name: 'list',
                        meta: { title: 'PC端软件清单', hidden: false },
                        component:  () => import('@/views/list/ListView.vue')
                    },
                    {
                        path: 'introduce',
                        name: 'introduce',
                        meta: { title: 'PC端自我介绍', hidden: false },
                        component:  () => import('@/views/introduce/IntroduceView.vue')
                    },
                    {
                        path: 'preview',
                        name: 'preview',
                        meta: { title: 'PC端预览PDF', hidden: false },
                        component:  () => import('@/views/PDFPreview.vue')
                    },
                    {
                        path: '/:pathMatch(.*)*',
                        name: 'NotFound',
                        redirect: '/home'
                    }
                ]
            },
            {
                path: 'm_home',
                name: 'm_home',
                component: M_HomeView

            },
            {
                path: 'm_main',
                name: 'm_main',
                component: () => import('@/views/main/MainView.vue'),
                meta: { title: '移动端主界面', hidden: false, threeMenu: true },
                children: [
                    {
                        path: 'm_recommendDetails',
                        name: 'm_recommendDetails',
                        meta: { title: '移动端软件详情页', hidden: false },
                        component:  () => import('@/views/details/M_RecommendDetailsView.vue')
                    },
                    {
                        path: 'm_list',
                        name: 'm_list',
                        meta: { title: '移动端软件清单', hidden: false },
                        component:  () => import('@/views/list/M_ListView.vue')
                    },
                    {
                        path: 'm_introduce',
                        name: 'm_introduce',
                        meta: { title: '移动端软件清单', hidden: false },
                        component:  () => import('@/views/introduce/M_IntroduceView.vue')
                    },
                    {
                        path: 'm_preview',
                        name: 'm_preview',
                        meta: { title: '移动端预览PDF', hidden: false },
                        component:  () => import('@/views/PDFPreview.vue')
                    },
                    {
                        path: '/:pathMatch(.*)*',
                        name: 'NotFound',
                        redirect: '/home'
                    }
                ]
            }
        ],
        meta: {
            keepAlive: false
        }
    }
]
const router = createRouter({
    history: process.env.NODE_ENV === 'production' ? createWebHistory('/') : createWebHistory(process.env.BASE_URL),
    routes
})
export default router